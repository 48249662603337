.section-two {
  background: $background_2;
  margin-top: -3px;
  padding-bottom: 20px;

  ::selection {
    background: $accent_orange_faded;
    color: $metalic_white;
  }

  ::-moz-selection {
    background: $accent_orange_faded;
    color: $metalic_white;
  }

  .content {
    padding: 50px 40px 0 40px;
    @media (max-width: 1000px) {
      padding: 50px 20px 0 20px;
    }
    .subtitle {
      font-size: 20px;
      padding: 2.5px 0;
      @media (max-width: 1000px) {
        text-align: center;
      }
    }
    .underline {
      width: 100px;
      height: 3.5px;
      background-color: $accent_orange;
      margin: 0.5px 0 15px 0;
      @media (max-width: 1000px) {
        margin: 0 auto 30px auto;
      }
    }
    .title {
      font-size: 40px;
      padding: 2.5px 0;
      @media (max-width: 1000px) {
        text-align: center;
      }
    }
    .cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      grid-gap: 20px;
      margin-top: 20px;
      .card {
        height: 275px;
      }
      @media (max-width: 1000px) {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 0px;
        .card {
          min-height: 175px;
          p {
            text-align: center !important;
          }
          h2 {
            min-height: inherit;
          }
        }
      }
    }
  }
}
