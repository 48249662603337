h1 {
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: inherit;
  padding: inherit;
  color: inherit;
}
@import "variables";

@import "card";
@import "contact";
@import "footer";
@import "hexagon";
@import "navbar";
@import "projectCard";
@import "projectList";
@import "sectionFour";
@import "sectionOne";
@import "sectionThree";
@import "sectionTwo";
@import "sectionFive";
@import "sectionSix";
@import "sidebar";
@import "project";
@import "articles";
