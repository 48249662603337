.section-five {
  width: 100%;
  background-color: $background_1;
  margin-top: -1px;
  margin-bottom: -1px;

  .content {
    padding: 40px 40px 50px 40px;
    @media (max-width: 1000px) {
      padding: 40px 10px 50px 10px;
    }
    .subtitle {
      font-size: 20px;
      padding: 2.5px 0;
      @media (max-width: 1000px) {
        text-align: center;
      }
    }
    .slick-arrow {
      display: none !important;
    }
    .slick-active {
      button::before {
        color: $accent_purple !important;
      }
    }
    .slick-dots {
      li {
        margin: 0 !important;
      }
      button::before {
        font-size: 8px !important;
      }
    }
    .underline {
      width: 100px;
      height: 3.5px;
      background-color: $accent_purple;
      margin: 0.5px 0 15px 0;
      @media (max-width: 1000px) {
        margin: 0 auto 30px auto;
      }
    }
    .title {
      font-size: 40px;
      padding: 2.5px 0;
      @media (max-width: 1000px) {
        text-align: center;
      }
    }

    .review {
      margin-top: 10px;
      max-width: 400px;
      padding-bottom: 25px;

      @media (max-width: 1000px) {
        margin: 0 auto;
        display: flex !important;
        flex-direction: column;
      }
      .quote {
        text-align: justify;
        margin: 0 5px;
        .purple {
          font-weight: bold;
        }
      }
      .stars {
        img {
          width: 100px;
          margin: 20px auto;
        }
      }
      .quote-author {
        display: flex !important;
        gap: 10px;
        align-items: center;
        margin: auto;
        img {
          width: 40px;
          border-radius: 50%;
        }
        .author {
          opacity: 0.75;
        }
      }
    }
  }
}
