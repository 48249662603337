$background_1: #20272f;
$background_2: #171c22;

$accent_purple: #b40fff;
$accent_purple_2: #b03aed;
$accent_indigo: #6c3fdb;

$accent_orange: #fc5b3b;
$accent_orange_faded: #ff8357;
$accent_orange_red: #f33019;

$creamy_white: #fee6d4;
$metalic_white: #d9d9d9;
