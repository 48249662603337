.section-one {
  padding-top: 100px;
  width: auto;
  background-color: $background_1;
  .content {
    padding: 20px 40px 0 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    @media (max-width: 1000px) {
      flex-direction: column;
      padding: 20px 20px 0 20px;
      img {
        max-width: 80vw;
      }
    }
    .binary {
      position: absolute;
      font-size: 11px;
      bottom: 50px;
      color: $background_2;
      @media (max-width: 1000px) {
        display: none;
      }
    }
    .subtitle {
      font-size: 20px;
      padding: 2.5px 0;
    }
    .title {
      font-size: 40px;
      padding: 2.5px 0;
    }
    .underline {
      width: 100px;
      height: 3.5px;
      background-color: $accent_purple;
      margin: 0.5px 0 15px 0;
    }
    .description {
      max-width: 385px;
      text-align: justify;
      font-size: 18px;
      padding: 50px 0 0 0;
    }
    .left {
      z-index: 500;
      padding-bottom: 50px;
    }
    .right {
      z-index: 500;
      position: relative;
      top: 0;
      left: 0;
      .marius {
        position: relative;
        top: 0;
        left: 0;
      }
      .hexagon {
        position: absolute;
        transition: all 0.25s linear;
        left: 0;
      }
    }
    .generator {
      position: absolute;
      bottom: -100px;
      width: 400px;
    }
  }
}
