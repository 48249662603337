.navbar {
  position: fixed;
  top: 0;
  left: 50%;
  height: 80px;
  @media (max-width: 1000px) {
    height: 80px;
  }
  width: 100%;
  transform: translateX(-50%);
  background: #171c22;
  z-index: 1000;
  nav {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    padding: 10px 10px 10px 10px;
    margin-bottom: -90px;
    transition: all 0.25s ease-in-out;
    &.mobile-height {
      padding-bottom: 100px;
      margin-bottom: -190px;
    }
    .logo {
      display: flex;
      align-items: center;
      img {
        margin-right: 15px;
      }
      .logo-text {
        font-size: 1.5rem;
        text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
      }
    }
    .flag {
      justify-self: center;
      img {
        width: 30px;
      }
    }
    .right {
      position: relative;
      display: flex;
      list-style: none;
      justify-self: end;
      li {
        margin-right: 28px;
        &:last-child {
          margin-right: 0;
        }
        font-size: 20px;
        a {
          transition: all 0.15s ease-in-out;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: $accent_purple;
            visibility: hidden;
            transform: scaleX(0);
            transition: all 0.1s linear;
          }
          &:hover {
            color: $accent_purple;
          }
          &.active {
            color: $accent_purple;
            &::before {
              visibility: visible;
              transform: scaleX(1);
            }
          }
        }
      }
      .hamburger {
        cursor: pointer;
        filter: invert(1);
        &.active {
          filter: invert(15%) sepia(72%) saturate(7498%) hue-rotate(280deg)
            brightness(107%) contrast(106%);
        }
      }
      .menu {
        background: #171c22;
        padding: 0 50px;
        position: absolute;
        right: -45px;
        top: 135%;
        height: 0;
        width: 100vw;
        text-align: right;
        transition: all 0.25s ease-in-out;
        overflow: hidden;

        li {
          margin: 0;
          padding: 2.5px 0;
        }
        &.show {
          height: 100px;
        }
      }
    }
  }
}
