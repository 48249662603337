.sidebar {
  position: fixed;
  left: 1%;
  top: calc(50%);
  transform: translateY(-50%);
  z-index: 100;
  img:hover {
    cursor: pointer;
  }
  img {
    margin-bottom: 2.5px;
  }
}
