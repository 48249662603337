.project-page {
  background-color: $background_1;
  padding: 150px 0 20px 0;
  .header {
    text-align: center;
    margin-bottom: 50px;
    .title {
      font-size: 40px;
      padding: 2.5px 0;
    }
    .subt .subtitle {
      font-size: 20px;
      padding: 2.5px 0;
    }
  }
  .main_picture {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    img {
      width: 50vw;
    }
  }
  .ant-divider {
    border-top: 1px solid $metalic_white !important;
  }
  .content {
    padding: 0 50px;
    .img_text {
      display: flex;
      justify-content: center;
      margin-bottom: 50px;
      gap: 50px;
      img {
        width: 100%;
      }
      .title {
        font-size: 30px;
        margin-bottom: 20px;
      }
    }
    .text_box {
      //   text-align: justify;
      margin-bottom: 50px;
      width: 100%;
      .title {
        font-size: 30px;
        margin-bottom: 20px;
      }
      .text {
        ul,
        li {
          padding: 2.5px 0;
          list-style-type: none;
        }
      }
    }
    .align-right {
      text-align: right;
    }
    .align-left {
      text-align: left;
    }
    .align-justify {
      text-align: justify;
    }
  }
}
