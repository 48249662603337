.section-three {
  width: auto;
  background-color: $background_1;

  .content {
    padding: 50px 40px 30px 40px;
    @media (max-width: 1000px) {
      padding: 50px 10px 30px 10px;
    }
    .subtitle {
      font-size: 20px;
      padding: 2.5px 0;
      @media (max-width: 1000px) {
        text-align: center;
      }
    }
    .underline {
      width: 100px;
      height: 3.5px;
      background-color: $accent_purple;
      margin: 0.5px 0 15px 0;
      @media (max-width: 1000px) {
        margin: 0 auto 30px auto;
      }
    }
    .title {
      font-size: 40px;
      padding: 2.5px 0;
      @media (max-width: 1000px) {
        text-align: center;
      }
    }
    .project-cards {
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      .proj_card {
        padding: 30px 0;
        margin: 20px 0;
      }
    }
    .view-more {
      padding: 5px;
      margin: auto;
      width: fit-content;
      display: flex;
      justify-content: center;
      color: white;
      transition: all 0.3s ease;
      border-radius: 5px;
      //text stroke
      box-shadow: 0 0 0 2px white;
    }
    .view-more:hover {
      color: #20272f;
      background-color: white;
    }
  }
}
