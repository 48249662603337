.contact {
  background-color: $background_1;
  padding-bottom: 100px;
  @media (max-width: 1000px) {
    padding-bottom: 20px;
  }

  .content {
    padding: 50px 40px 0 40px;
    @media (max-width: 1000px) {
      padding-top: 50px;
    }
  }

  .center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .subtitle {
    font-size: 20px;
    padding: 2.5px 0;
    @media (max-width: 1000px) {
      text-align: center;
    }
  }
  .title {
    font-size: 40px;
    padding: 2.5px 0;
    @media (max-width: 1000px) {
      text-align: center;
    }
  }

  .underline {
    width: 100px;
    height: 3.5px;
    background-color: $accent_purple;
    margin: 30px auto 30px auto;

    @media (max-width: 1000px) {
      margin: 0 auto 30px auto;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    .row {
      margin-bottom: 20px;

      a {
        transition: color 0.2s ease-in-out;
      }
      a:hover {
        color: $accent_purple;
      }

      .title {
        font-size: 30px;
        margin-bottom: 15px;
      }
      .desc-card-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 25px;
        gap: 20px;
        @media (max-width: 1000px) {
          flex-direction: column;
        }
      }

      .desc-card-wrapper-2 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 25px;
        gap: 20px;
        img {
          width: 30px;
        }
        > div:hover {
          filter: brightness(0.8);
        }
      }
      .desc {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        background: #171c22;
        transition: all 0.2s ease-in-out;
        padding: 20px;
        border-radius: 10px;

        width: 250px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        h2 {
          margin: 7.5px;
        }
        > img {
          width: 60px;
        }
        div {
          min-width: 60%;
        }
      }

      .desc:hover {
        background: #384453;
      }
    }
  }
  .ant-form {
    margin: auto;
    width: 600px;
    @media (max-width: 1000px) {
      width: 80vw;
    }
    .ant-input {
      background-color: $metalic_white !important;
    }
    .ant-input:focus {
      background-color: white !important;
    }
    .ant-input:hover {
      background-color: white !important;
    }
    //give margin to error text
    .ant-form-item-explain {
      margin-top: 2px;
      margin-bottom: 10px;
      //make text orange
      &-error {
        color: $accent_purple !important;
      }
    }
    .ant-input-status-error {
      border-color: $accent_purple !important;
    }
  }

  //antd notification checkmark purple instead of green
}

.ant-notification-notice-with-icon {
  svg {
    color: $accent_purple !important;
  }
}
